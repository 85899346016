import React, { useEffect, useState } from 'react';
import Header from '../contents/header';
import Footer from '../contents/footer';
import { Link, useParams } from 'react-router-dom';
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import SEO from '../seo';

const Category = ({categorialista}) => {

  let { param1, param2 } = useParams();

  const [categlistMain, setcateglistMain] = useState([]);
  const [categtp, setCategTp] = useState(null);
  const [categtptitle, setCategTpTitle] = useState("");
  const [pagedom, setPageDom] = useState(null);
  const [categtitle, setCategTitle] = useState("");
  const [seodesc, setSeodesc] = useState(null);
  const [imgseo, setImgSeo] = useState(null);

  function formatProductNameForURL(productName) {
    // Remover acentuação
    const withoutAccents = productName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Remover caracteres especiais, exceto espaços e hifens
    const cleaned = withoutAccents.replace(/[^a-zA-Z0-9\s-]/g, '');
    // Substituir espaços por hífens
    const withHyphens = cleaned.replace(/\s+/g, '-');
    // Converter para minúsculas
    const urlFriendly = withHyphens.toLowerCase();
    return urlFriendly;
  }

  useEffect(() => {

    const q = query(collection(db, "category"), where("id", "==", param1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const categs = [];
      querySnapshot.forEach((doc) => {
        categs.push(doc.data());
      });
      

      if(categs[0].type == 1){
        setCategTp(1);
        setCategTpTitle("Categorias");
        setCategTitle(categs[0].name);
        setSeodesc(categs[0].desc);

        // puxando subs-categ

        const qsubs = query(collection(db, "category"), where("categmae", "==", param1));
        const unsubscribe = onSnapshot(qsubs, (querySnapshot) => {
          const subscateg = [];
          querySnapshot.forEach((doc) => {
              subscateg.push(doc.data());
          });

          const subscategdom = [];

          for(var i =0; i < subscateg.length; i++){
            subscategdom.push(
                <div className='col-md-4 col-sm-12'>
                  <div className='card containersubcateg'>

                    <img src={subscateg[i].img} alt={subscateg[i].name} width="116" height="116" loading="lazy"/>

                    <div className='divrigthsubs'>
                      <h1 style={{fontSize: 18}}>{subscateg[i].name}</h1>
                      <Link to={'/categoria/'+subscateg[i].id+'/'+formatProductNameForURL(subscateg[i].name)}>Ver Todos</Link>
                    </div>

                  </div>
                </div>
            );
          }

          setPageDom(subscategdom);
        });

        //setcateglistMain(categs);
      }else{

        setCategTp(2);
        setCategTpTitle("Produtos");
        setCategTitle(categs[0].name);
        setSeodesc(categs[0].desc);
        setImgSeo(categs[0].img);

        // pegando todas as categorias disponiveis

        const categoriasDom = [];
        const produtosDom = [];

        const qcategs = query(collection(db, "category"), where("type", "==", 1));
        const unsubscribecategs = onSnapshot(qcategs, (querySnapshot) => {
          const categorias = [];
          const arrPrincipal = [];
          querySnapshot.forEach((doc) => {
            categorias.push(doc.data());

            var arrteste = doc.data();

            //tt.push(doc.data());

            // puxando subs
            const qcategsubs = query(collection(db, "category"), where("type", "==", 2), where("categmae", "==", doc.data().id));
            const unsubscribecategsubs = onSnapshot(qcategsubs, (querySnapshot) => {
              const categsubs = [];
              querySnapshot.forEach((docc) => {
                 categsubs.push(docc.data());
              });

              if(categsubs){
                arrteste.subs = categsubs;
                arrteste.numsubs = categsubs.length;
              }else{
                arrteste.subs = [];
                arrteste.numsubs = 0;
              }


              console.log("==========>", arrteste);

              // render categ

              const renderElement = (array) =>{
                const arr = [];

                for(var i =0; i < array.length; i++){
                  arr.push(<li style={{listStyle: 'none', marginTop: 10, borderBottom: '1px solid #333'}}><Link  to={'/categoria/'+array[i].id+'/'+formatProductNameForURL(array[i].name)} style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{array[i].name}</Link></li>);
                }

                return arr;
              }

              categoriasDom.push(
                <div className='card card-body cardpagecateg'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <Link to="">{arrteste.name}</Link>
                    </div>
                    <div className='col-md-4' style={{textAlign: 'right'}}>
                      <button className='dropdown-toggle dropdown-toggle-split' data-bs-toggle="collapse" aria-expanded="false" aria-controls={arrteste.id} data-bs-target={'#'+arrteste.id} style={{border: 'none', backgroundColor: 'transparent'}}><span className="visually-hidden" style={{paddingLeft: 10}}>Toggle Dropend</span></button>
                    </div>
                  </div>

                  <div className="collapse" id={arrteste.id}>
                    <div className="card card-body">
                      <ul style={{display: 'flex', flexDirection: 'column', padding: 0}}>
                        {arrteste.numsubs ?(
                          renderElement(arrteste.subs)
                        ):(
                          <p>Nenhuma sub-categoria encontrada...</p>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              );

            });
            
          });


          

          if(arrPrincipal){

            //arrPrincipal.push([{name: "teste"}]);

            

            for(var v = 0; v < arrPrincipal.length; v++){
              console.log("olaaaaaaaaa");
            }

            

            arrPrincipal.map((item, index) => {

              
              
              categoriasDom.push(
                <div className='card card-body cardpagecateg'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <Link to="">{item.name}</Link>
                    </div>
                    <div className='col-md-4' style={{textAlign: 'right'}}>
                      <button className='dropdown-toggle dropdown-toggle-split' data-bs-toggle="collapse" aria-expanded="false" aria-controls={item.id} data-bs-target={'#'+item.id} style={{border: 'none', backgroundColor: 'transparent'}}><span className="visually-hidden" style={{paddingLeft: 10}}>Toggle Dropend</span></button>
                    </div>
                  </div>

                  <div className="collapse" id={item.id}>
                    <div className="card card-body">
                      <ul>
                        {item.subs ?(
                          <p>Achou</p>
                        ):(
                          <p>Não achou</p>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            });

           /* for(var v = 0; v < arrPrincipal.length; v++){
              var subsdom = null;
              if(arrPrincipal[v].numsubs>0){
                subsdom = [];
                subsdom.push(arrPrincipal[v].subs);
              }else{
                subsdom = "Nenhuma SubCategoria";
              }

              categoriasDom.push(
                <div className='card card-body cardpagecateg'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <Link to="">{categorias[v].name}</Link>
                    </div>
                    <div className='col-md-4' style={{textAlign: 'right'}}>
                      <button className='dropdown-toggle dropdown-toggle-split' data-bs-toggle="collapse" aria-expanded="false" aria-controls={categorias[v].id} data-bs-target={'#'+categorias[v].id} style={{border: 'none', backgroundColor: 'transparent'}}><span className="visually-hidden" style={{paddingLeft: 10}}>Toggle Dropend</span></button>
                    </div>
                  </div>

                  <div className="collapse" id={categorias[v].id}>
                    <div className="card card-body">
                      <ul>
                        {subsdom}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            }*/
          }
          
        });


        // pegando os produtos da categoria

        const qprods = query(collection(db, "product"), where("category", "==", categs[0].name));
        const unsubscribe = onSnapshot(qprods, (querySnapshot) => {
          const produtos = [];
          querySnapshot.forEach((doc) => {
            produtos.push(doc.data());
          });

          if(produtos.length == 0){
            setPageDom(<div className='row'>
              <div className='col-md-4 col-sm-12'>
                <div className='categs_container'>
                  <h2>KTX Brasil</h2>
                  <hr></hr>
                  {categoriasDom}
                </div>
              </div>

              <div className='col-md-8 col-sm-12'>
                <h1>Nenhum produto encontrado!</h1>
                <Link to="">Voltar ao Inicio</Link>
              </div>
            </div>);
          }else{
            for(var v=0; v < produtos.length; v++){
              produtosDom.push(
                <div className='row container_prods_categorias'>
                  <div className='col-md-8'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <img src={produtos[v].fotos[0]} alt={produtos[v].name} width="100%"/>
                      </div>
                      <div className='col-md-8'>
                        <h1 className='title_prod_categ'>{produtos[v].name}</h1>
                        <section className='container_avalia_categ'>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                          <i class="bi bi-star-fill"></i>
                        </section>
                        <p>
                          {produtos[v].minidesc.substr(0, 100)}....
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <p className='disp_prod_categ'>Disponivel</p>
                    <Link to={'/produto/'+produtos[v].id+'/'+formatProductNameForURL(produtos[v].name)} className='btn btn-secondary'>Ver Detalhes</Link>
                  </div>
                </div>
              );
            }
            setPageDom(<div className='row'>
              <div className='col-md-4 col-sm-12'>
                <div className='categs_container'>
                  <h2>KTX Brasil</h2>
                  <hr></hr>
                  {categoriasDom}
                </div>
              </div>

              <div className='col-md-8 col-sm-12'>
                {produtosDom}
              </div>
            </div>);
          }
        });

      }

    });
    
  }, [param1, param2]);


  return <div>
    <Header listadecateg={categorialista}/>
    <SEO
        title={categtitle+' - KTX BRASIL'}
        description={seodesc}
        image={imgseo}
        url="https://ktxbrasil.com.br"
    />
    <div className='container'>
        <h1 style={{fontSize: 36, color: '#3D464D', textTransform: 'capitalize', marginTop: 20}}>{categtitle}</h1>
        <div className='row' style={{marginTop: 30}}>
          <div className='col-md-2'>
            <p style={{fontSize: 20, color: '#3D464D', fontWeight: 700, marginBottom: 0}}>{categtptitle}</p>
          </div>
          <div className='col-md-10' style={{borderTop: '1px solid #3D464D', marginTop: 10}}>
          </div>
        </div>

        <div className='row' style={{marginTop: 10}}>

          {pagedom}
          
        </div>
    </div>
    <Footer listadecateg={categorialista}/>
  </div>;
};

export default Category;